import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesOrig from './MobileMenuItem.scss';
import stylesClean from './MobileMenuItemClean.scss';

const BackButton = ({ label, onClick, isCleanMobileNavActive }) => {
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;

  return (
    <div
      className={classNames(
        styles.mobileMenuItem,
        styles.mobileMenuItem__fullWidth,
        styles.mobileMenuItem__chevronLeft,
        {
          [styles.mobileMenuItem__static]: isCleanMobileNavActive,
        }
      )}
    >
      <button
        className={classNames(styles.mobileMenuItem__button)}
        data-testid="nav-back-button"
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

BackButton.defaultProps = {
  isCleanMobileNavActive: false,
};

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCleanMobileNavActive: PropTypes.bool,
};

export default BackButton;
