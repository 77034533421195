import { notifyNavAnalytics } from './navigation/notify-analytics';

export const skipLinks = [
  {
    name: 'Skip to main content',
    url: '#main',
  },
  {
    name: 'Skip to footer',
    url: '#footer',
  },
];

export const getTopLinks = baseUrl => {
  const links = [
    {
      name: 'About us',
      url: '/customer-services/about-us',
      handler: () => notifyNavAnalytics('pn', 'About us'),
    },
    {
      name: 'John Lewis Brands',
      url: '/content/discover-john-lewis-brands',
      handler: () => notifyNavAnalytics('pn', 'John Lewis Brands'),
    },
    {
      name: 'Brands A-Z',
      url: '/brands',
      handler: () => notifyNavAnalytics('pn', 'Brands A-Z'),
    },
    {
      name: 'Our shops',
      url: '/our-shops',
      handler: () => notifyNavAnalytics('pn', 'Our shops'),
    },
    {
      name: 'Customer services',
      url: '/customer-services',
      handler: () => notifyNavAnalytics('pn', 'Customer services'),
    },
    {
      name: 'Our services',
      url: '/our-services',
      handler: () => notifyNavAnalytics('pn', 'Our services'),
    },
    {
      name: 'Track order',
      url: `${baseUrl || ''}/track-order`,
      handler: () => notifyNavAnalytics('pn', 'Track order'),
    },
    {
      name: 'My John Lewis',
      url: '/our-services/my-john-lewis',
      handler: () => notifyNavAnalytics('pn', 'My John Lewis'),
    },
    {
      name: 'Partnership Credit Card',
      url:
        'https://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50|http://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072%7Cprenavigation%7Cxc50',
      external: true,
      handler: () => notifyNavAnalytics('pn', 'Partnership Credit Card'),
    },
    {
      name: 'Insurance',
      url: 'https://www.johnlewisfinance.com/insurance.html?track=JLj0008',
      external: true,
      handler: () => notifyNavAnalytics('pn', 'Insurance'),
    },
  ];

  return links;
};

export const getTopLinksWithMenu = baseUrl => {
  const links = [
    {},
    {},
    {},
    {},
    {},
    {},
    {
      name: 'Shopping with us',
      menuLinks: [
        {
          name: 'My John Lewis rewards',
          url: '/our-services/my-john-lewis',
        },
        {
          name: 'Services and experiences',
          url: '/our-services',
        },
        {
          name: 'Gift cards',
          url: '/customer-services/prices-and-payment/gift-cards',
        },
        {
          name: 'Our app',
          url: '/customer-services/shopping-with-us/our-apps',
        },
        {
          name: 'Brands A-Z',
          url: '/brands',
        },
      ],
    },
    {
      name: 'Help',
      menuLinks: [
        {
          name: 'Customer services',
          url: '/customer-services',
        },
        {
          name: 'Track your order',
          url: `${baseUrl || ''}/track-order`,
        },
        {
          name: 'Returns and refunds',
          url: '/customer-services/returns',
        },
        {
          name: 'Delivery and collection',
          url: '/customer-services/delivery-information',
        },
        {
          name: 'Product support',
          url: 'https://productsupport.johnlewis.com',
        },
        {
          name: 'Contact us',
          url: '/customer-services/contact-us',
        },
      ],
    },
    {
      name: 'John Lewis Money',
      menuLinks: [
        {
          name: 'Partnership Credit Card',
          url:
            'https://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50|http://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072%7Cprenavigation%7Cxc50',
          external: true,
        },
        {
          name: 'Home insurance',
          url: 'https://www.johnlewisfinance.com/insurance/home-insurance.html',
          external: true,
        },
        {
          name: 'Pet insurance',
          url: 'https://www.johnlewisfinance.com/insurance/pet-insurance.html',
          external: true,
        },
        {
          name: 'Car insurance',
          url: 'https://www.johnlewisfinance.com/insurance/car-insurance.html',
          external: true,
        },
        {
          name: 'Travel money',
          url: 'https://www.johnlewisfinance.com/currency.html',
          external: true,
        },
        {
          name: 'Investments',
          url: 'https://www.johnlewisfinance.com/investments.html',
          external: true,
        },
        {
          name: 'International payments',
          url: 'https://www.johnlewisfinance.com/money-transfer.html',
          external: true,
        },
      ],
    },
    {
      name: 'Our stores',
      url: '/our-shops',
      menuLinks: [],
    },
  ];

  return links;
};

export const getAccountLinks = baseUrl => [
  {
    name: 'My account',
    url: '/my-account',
  },
  {
    name: 'Online orders',
    url: `${baseUrl || ''}/orders`,
  },
  {
    name: 'My John Lewis rewards',
    url: `${baseUrl || ''}/rewards`,
  },
  {
    name: 'My reviews',
    url: `${baseUrl || ''}/my-reviews`,
  },
  {
    name: 'Wish list',
    url: `${baseUrl || ''}/wish-list`,
  },
  {
    name: 'Appointments',
    url: '/appointments/manage',
  },
  {
    name: 'Sign out',
    url: '/sign-out',
  },
];

const FEEDBACK_LINK =
  'https://edigitalsurvey.com/survey/enter/s/ESV-136225211/data2/mobile/collection_url/';

export const feedbackLink = {
  name: 'Feedback',
  text: 'Your comments help us improve our website',
  button: { label: 'Leave Feedback', url: FEEDBACK_LINK },
  feedback: true,
};

export const getStaticMobileMenus = (
  baseUrl,
  signedIn,
  isStoreFinderMobileActive
) => {
  const mobileLinks = [
    {
      name: 'My Account',
      url: '/my-account',
      isDisplayedInGlobalNav: false,
      child: !signedIn
        ? [
            {
              name: 'Sign in',
              url: '/sign-in',
              isDisplayedInGlobalNav: false,
              accountHeaderOnly: true,
            },
            {
              name: 'Track Order',
              url: `${baseUrl || ''}/track-order`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'My John Lewis rewards',
              url: `${baseUrl || ''}/rewards`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'Wish List',
              url: `${baseUrl || ''}/wish-list`,
              isDisplayedInGlobalNav: false,
            },
          ]
        : [
            {
              name: 'View my account',
              url: '/my-account',
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'Track my order',
              url: `${baseUrl || ''}/track-order`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'My John Lewis rewards',
              url: `${baseUrl || ''}/rewards`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'My reviews',
              url: `${baseUrl || ''}/my-reviews`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'Wish List',
              url: `${baseUrl || ''}/wish-list`,
              isDisplayedInGlobalNav: false,
            },
            {
              name: 'Sign out',
              url: 'https://www.johnlewis.com/sign-out',
              isDisplayedInGlobalNav: false,
              accountHeaderOnly: true,
            },
          ],
    },
    {
      name: 'Help',
      url: '/customer-services',
      isDisplayedInGlobalNav: false,
      child: [
        {
          name: 'Customer services',
          url: '/customer-services',
          isDisplayedInGlobalNav: false,
        },
        {
          name: 'Delivery & collection',
          url: '/customer-services/delivery-information',
          isDisplayedInGlobalNav: false,
        },
        {
          name: 'Returns & refunds',
          url: '/customer-services/returns',
          isDisplayedInGlobalNav: false,
        },
        {
          name: 'Shop finder',
          url: '/our-shops',
          isDisplayedInGlobalNav: false,
        },
      ],
    },
    {
      name: 'Services and experiences',
      url: '/our-services',
      isDisplayedInGlobalNav: false,
      child: [
        {
          name: 'Our services',
          url: '/our-services',
          isDisplayedInGlobalNav: false,
        },
        {
          name: 'More from us',
          url: '/our-services/more-from-john-lewis',
          isDisplayedInGlobalNav: false,
        },
      ],
    },
    feedbackLink,
  ];

  if (isStoreFinderMobileActive) {
    mobileLinks[1].child.splice(3, 1);
  }

  return mobileLinks;
};

export const getTabbedMobileMenus = (baseUrl, signedIn) => [
  {
    id: 0,
    name: 'My Account',
    url: '/my-account',
    child: !signedIn
      ? [
          {
            name: 'Sign in',
            url: '/sign-in',
          },
          {
            name: 'Track my order',
            url: `${baseUrl || ''}/track-order`,
          },
          {
            name: 'My John Lewis rewards',
            url: `${baseUrl || ''}/rewards`,
          },
          {
            name: 'Wish list',
            url: `${baseUrl || ''}/wish-list`,
          },
        ]
      : [
          {
            name: 'View my account',
            url: '/my-account',
          },
          {
            name: 'Track my order',
            url: `${baseUrl || ''}/track-order`,
          },
          {
            name: 'My John Lewis rewards',
            url: `${baseUrl || ''}/rewards`,
          },
          {
            name: 'Wish list',
            url: `${baseUrl || ''}/wish-list`,
          },
          {
            name: 'Sign out',
            url: 'https://www.johnlewis.com/sign-out',
          },
        ],
  },
  {
    id: 1,
    name: 'Shop',
  },
  {
    id: 2,
    name: 'Services',
    url: '/our-services',
    child: [
      {
        id: 'partership-card',
        name: 'Partnership credit card',
        url:
          'https://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50|http://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072%7Cprenavigation%7Cxc50',
      },
      {
        id: 'my-john-lewis',
        name: 'My John Lewis',
        url: '/our-services/my-john-lewis',
      },
      {
        id: 'our-services',
        name: 'Services',
        url: '/our-services',
      },
      {
        id: 'insurance',
        name: 'Insurance',
        url: 'https://www.johnlewisfinance.com/insurance.html?track=JLj0008',
      },
    ],
  },
  {
    id: 3,
    name: 'Help',
    url: '/customer-services',
    child: [
      {
        id: 'customer-services',
        name: 'Customer services',
        url: '/customer-services',
      },
      {
        id: 'about-us',
        name: 'About us',
        url: '/customer-services/about-us',
      },
      {
        id: 'track-my-order',
        name: 'Track my order',
        url: `${baseUrl}/track-order`,
      },
      {
        id: 'store-finder',
        name: 'Store finder',
        url: '/our-shops',
      },
      {
        id: 'returns',
        name: 'Returns and refunds',
        url: '/customer-services/returns',
      },
      {
        id: 'delivery-and-collections',
        name: 'Delivery and collections',
        url: '/customer-services/delivery-information',
      },
    ],
  },
  {
    id: 4,
    name: 'Brands',
    url: '/brands',
    child: [
      {
        id: 'brands-az',
        name: 'Brands A-Z',
        url: '/brands',
      },
      {
        id: 'john-lewis-brands',
        name: 'John Lewis Brands',
        url: '/content/discover-john-lewis-brands',
      },
    ],
  },
];
