import React, { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useAppDownloadBannerContext } from '../../context/AppDownloadBannerContext';
import appDownloadLogoSrc from '../../../assets/logos/app-banner.png';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import getMonetateParams from '../../utils/monetate/getMonetateParams';
import styles from './app-download-banner.scss';
import { logoAssetsPath } from '../logo/LogoBP';

const AppDownloadBanner = () => {
  const {
    showAppDownloadBanner,
    setShowAppDownloadBanner,
  } = useAppDownloadBannerContext();

  const {
    features: { monetateExperiences },
    envVars,
  } = useContext(EnvVarsFeaturesContext);

  const parameters = getMonetateParams(
    { monetateExperiences },
    'app-download-banner'
  );

  const hideAppDownloadBanner = () => {
    Cookies.set('JL_App_Download_Banner', true);
    setShowAppDownloadBanner(false);
  };

  const onViewHandler = () => {
    hideAppDownloadBanner();
    window.location.href = parameters.appDownloadLink;
  };

  const logoWithAssetsPath = logoAssetsPath(envVars?.assetsPath);

  const setVisibility = () => {
    if (window.scrollY > 150 && showAppDownloadBanner) {
      setShowAppDownloadBanner(false);
    } else if (window.scrollY === 0) {
      setShowAppDownloadBanner(true);
    }
  };

  const update = () => {
    requestAnimationFrame(() => setVisibility());
  };

  useEffect(() => {
    if (Cookies.get('JL_App_Download_Banner') !== 'true') {
      window.addEventListener('scroll', update);
    }

    return () => window.removeEventListener('scroll', update);
  }, [showAppDownloadBanner]);

  return (
    <>
      {showAppDownloadBanner && (
        <div
          className={styles.bannerContainer}
          data-testid="app-download-banner"
        >
          <div className={styles.leftContent}>
            <img
              height="71px"
              src={logoWithAssetsPath(appDownloadLogoSrc)}
              alt="John Lewis & Partners"
            />
            <div>
              <div className={styles.title}>John Lewis & Partners</div>
              <div className={styles.subTitle}>
                Our free app makes it easier to shop with us.
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <button
              data-testid="closeBtn"
              className={styles.closeBtn}
              onClick={hideAppDownloadBanner}
            >
              X
            </button>
            <button
              data-testid="viewBtn"
              className={styles.viewBtn}
              onClick={onViewHandler}
            >
              View
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AppDownloadBanner;
