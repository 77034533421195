import React from 'react';
import classNames from 'classnames';
import MenuIconOpen from 'jl-design-system/elements/icons-jb/Close24PxOutlined';
import MenuIconClosed from 'jl-design-system/elements/icons-jb/Menu24PxOutlined';

// eslint-disable-next-line import/no-named-as-default
import AccountLinks from '../account-links/AccountLinks';
// eslint-disable-next-line import/no-named-as-default
import Basket from '../basket/Basket';
import StoreFinderIcon from '../store-finder-icon/StoreFinderIcon';

import styles from './user-tools.scss';

function UserTools({
  isMenuOpen,
  onMenuButtonClick,
  isTabbedNavActive,
  isTypeaheadOpen,
  isStoreFinderMobileActiveA,
}) {
  const menuButtonChildren = (
    <>
      <span className={styles.visuallyHidden}>
        {isMenuOpen ? 'Close' : 'Menu'}
      </span>

      {isMenuOpen ? (
        <MenuIconOpen
          data-testid="nav-menu-close-icon"
          box
          title="Close Menu"
        />
      ) : (
        <MenuIconClosed data-testid="nav-menu-icon" box title="Open Menu" />
      )}

      <span
        className={classNames(styles.navListItemLabel, {
          [styles.navListLabel__tn]: isTabbedNavActive,
        })}
        data-testid="nav-menu-label"
      >
        {isMenuOpen ? 'Close' : 'Menu'}
      </span>
    </>
  );

  return (
    <nav
      data-testid="user-tools-nav"
      className={classNames(styles.nav, {
        [styles.nav__active]: isTypeaheadOpen,
        [styles.nav__tn]: isTabbedNavActive,
        [styles.nav__storeFinder]: isStoreFinderMobileActiveA,
      })}
    >
      <ul className={classNames(styles.navList)}>
        {isStoreFinderMobileActiveA && (
          <li
            className={classNames(styles.navListItem, styles.storeFinder, {
              [styles.navListItem__storeFinder]: isStoreFinderMobileActiveA,
            })}
          >
            <StoreFinderIcon />
          </li>
        )}
        <li
          className={classNames(styles.navListItem, {
            [styles.navListItem__tn]: isTabbedNavActive,
            [styles.navListItem__storeFinder]: isStoreFinderMobileActiveA,
          })}
        >
          <AccountLinks showGreeting isTabbedNavActive={isTabbedNavActive} />
        </li>
        <li
          className={classNames(styles.navListItem, {
            [styles.navListItem__tn]: isTabbedNavActive,
            [styles.navListItem__storeFinder]: isStoreFinderMobileActiveA,
          })}
        >
          <Basket isTabbedNavActive={isTabbedNavActive}>
            <span
              className={classNames(styles.navListItemLabel, {
                [styles.navListLabel__tn]: isTabbedNavActive,
              })}
              data-testid="basket-icon-label"
            >
              Basket
            </span>
          </Basket>
        </li>
        <li
          className={classNames(styles.navListItem, styles.menu, {
            [styles.menu__tn]: isTabbedNavActive,
            [styles.navListItem__storeFinder]: isStoreFinderMobileActiveA,
          })}
          data-testid="menu-icon"
        >
          <a
            href="/menu"
            className={styles.navListItemLink}
            data-testid="nav-link--menu"
            onClick={onMenuButtonClick}
            role="button"
            aria-expanded={isMenuOpen}
            aria-controls="main-navigation-menu"
          >
            {menuButtonChildren}
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default UserTools;
