import React from 'react';
import classnames from 'classnames';
import styles from './link-list.scss';

const LinkList = ({ links, Item, className, children, isNewPreNavActive }) => (
  <ul className={classnames(styles.list, className)}>
    {links.map(link => (
      <Item
        key={link.name}
        url={link.url}
        external={link.external}
        handler={link.handler}
        isNewPreNavActive={isNewPreNavActive}
        menuLinks={link.menuLinks || []}
      >
        {link.name}
      </Item>
    ))}
    {children}
  </ul>
);

export default LinkList;
