import React from 'react';
import classNames from 'classnames';
import LinkList from '../ui/link-list/LinkList';
import HeaderLink from '../link/Link';
import PrenavLink from '../prenav-link/PrenavLink';
import SkipLink from '../skip-link/SkipLink';

import styles from './link-list.scss';

const TopLinks = ({ links, isNewPreNavActive }) => (
  <LinkList
    links={links}
    Item={isNewPreNavActive ? PrenavLink : HeaderLink}
    className={classNames(styles.links, {
      [styles.links__prenav]: isNewPreNavActive,
    })}
    isNewPreNavActive={isNewPreNavActive}
  />
);

const SkipLinks = ({ links }) => (
  <LinkList links={links} Item={SkipLink} className={styles.skip} />
);

export { TopLinks, SkipLinks };
