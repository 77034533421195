import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import stylesOrig from './MobileMenuItem.scss';
import stylesClean from './MobileMenuItemClean.scss';
import offersCategory from '../offers-category';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';
import filterMenuData from '../../../utils/menu/filterMenuData';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';

const MobileMenuItem = ({
  label,
  url,
  className,
  onClick,
  child,
  hierarchy: parentHierarchy,
  children,
  isCleanMobileNavActive,
  isStaticMenu,
  level,
  isOffersRemoveViewMoreActive,
}) => {
  const styles = isCleanMobileNavActive ? stylesClean : stylesOrig;
  const isButton = child?.length > 0;
  const isAnchor = url && !isButton;
  const isOffer = offersCategory(label, 1);
  const offersChildren =
    filterMenuData(child, 'MOBILE_WEB')?.filter(item => !!item.url) || [];
  const hierarchy = [...parentHierarchy, label];
  const sendAnalytics = (tail = [], menuName = '') => {
    notifyAnalytics([...hierarchy, ...tail].join('|'));
    if (isStaticMenu && menuName) {
      dispatchMonetateQEvent(
        `mobile-menu-click.${menuName.replace(/ /g, '-').toLowerCase()}`
      );
    }
  };

  const advanceToChild = e => {
    sendAnalytics();
    onClick(e);
  };

  if (!isAnchor && !isButton) {
    return <></>;
  }

  return (
    <>
      <li
        className={classNames(styles.mobileMenuItem, className, {
          [styles.mobileMenuItem__static]:
            isStaticMenu && isCleanMobileNavActive,
          [styles.mobileMenuItem__topLevel]:
            level === 0 && isCleanMobileNavActive,
          [styles.mobileMenuItem__noUnderline]:
            isCleanMobileNavActive && isOffer && offersChildren.length > 0,
        })}
      >
        {isAnchor && (
          <a
            href={url}
            className={classNames(styles.mobileMenuItem__link, {
              [styles.mobileMenuItem__chevron]:
                isCleanMobileNavActive && level === 0,
              [styles.mobileMenuItem__signOutLink]:
                isCleanMobileNavActive && label.toLowerCase() === 'sign out',
            })}
            onClick={() => sendAnalytics([], label)}
            data-testid={formatTestId(label, 'nav-')}
            data-hierarchy={parentHierarchy.join('|')}
          >
            {label}
          </a>
        )}
        {isButton && (
          <button
            onClick={advanceToChild}
            data-testid={formatTestId(label, 'nav-')}
            className={classNames(
              styles.mobileMenuItem__button,
              styles.mobileMenuItem__chevron,
              styles.mobileMenuItem__button__nonBoldButton,
              {
                [styles.offersLabel]: isOffer,
                [styles.offersLabel__topLevel]:
                  isOffer && isCleanMobileNavActive && level === 0,
              }
            )}
          >
            {label}
          </button>
        )}
        {isOffer && offersChildren.length > 0 && (
          <ul className={styles.offersSubMenu}>
            {offersChildren.slice(0, 3).map(nestedItem => (
              <li className={styles.mobileMenuItem} key={nestedItem.name}>
                <a
                  className={classNames(styles.mobileMenuItem__link)}
                  onClick={() => sendAnalytics([nestedItem.name])}
                  href={nestedItem.url}
                >
                  {nestedItem.name}
                </a>
              </li>
            ))}
            {!isOffersRemoveViewMoreActive && offersChildren.length > 3 && (
              <li
                className={classNames(
                  styles.mobileMenuItem,
                  styles.mobileMenuItem__viewMoreLink
                )}
              >
                <button
                  className={classNames(styles.mobileMenuItem__button)}
                  onClick={advanceToChild}
                >
                  View more
                </button>
              </li>
            )}
          </ul>
        )}
        {children}
      </li>
      {isCleanMobileNavActive && isOffer && offersChildren.length > 0 && (
        <div className={styles.mobileMenuItem__fullWidthLine} />
      )}
    </>
  );
};

MobileMenuItem.defaultProps = {
  url: '',
  className: '',
  onClick: undefined,
  child: undefined,
  hierarchy: [],
  children: undefined,
  isCleanMobileNavActive: false,
  isOffersRemoveViewMoreActive: false,
};

MobileMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  child: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  isCleanMobileNavActive: PropTypes.bool,
  isOffersRemoveViewMoreActive: PropTypes.bool,
};

export default MobileMenuItem;
