import React, { createContext, useContext, useState } from 'react';

const Context = createContext();

export const useAppDownloadBannerContext = () => useContext(Context);

export const AppDownloadBannerProvider = ({ children, initialValue }) => {
  const [showAppDownloadBanner, updateAppDownloadBanner] = useState(
    initialValue.appDownloadBanner || false
  );

  const setShowAppDownloadBanner = value => {
    if (initialValue.appDownloadBanner) {
      updateAppDownloadBanner(value);
    }
  };

  return (
    <Context.Provider
      value={{
        showAppDownloadBanner,
        setShowAppDownloadBanner,
      }}
    >
      {children}
    </Context.Provider>
  );
};
