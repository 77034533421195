import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import DesktopMenu from './desktop-menu/DesktopMenu';
import MobileMenu from './mobile-menu/MobileMenu';
import getNavigation from '../../../client/fetch/get-navigation';
import {
  addMediaQueryListener,
  isTabletPortrait,
} from '../../utils/device/identifyDevice';
import SEOLinks from './seo-links/SEOLinks';
import postMonetateImpression from '../../utils/monetate/postMonetateImpression';
import isMonetateGroup from '../../utils/monetate/isMonetateGroup';

export const fetchChildCategories = (queryParams, subset) => ({ id }) =>
  getNavigation({ id, depth: 4, subset, queryParams });

function Navigation({ mobileNavShown }) {
  const {
    categories,
    envVars: { monetate },
    features: { monetateExperiences, displaySEOLinks },
    headerClientConfigQueryParameters,
  } = useContext(EnvVarsFeaturesContext);
  const [flyoutCategories, setFlyoutCategories] = useState({});

  const isTabbedNavActive = isMonetateGroup(
    { monetateExperiences },
    'tabbed-nav-design'
  );

  const isCleanMobileNavActive = isMonetateGroup(
    { monetateExperiences },
    'clean-mobile-nav-design'
  );

  const menus = [
    {
      id: 'shop-by-department',
      name: 'Shop by department',
      child: categories.child,
    },
  ];

  const fetch = fetchChildCategories(
    headerClientConfigQueryParameters,
    'MOBILE_WEB,FLYOUT'
  );

  const fetchFlyoutCategories = async () => {
    const navCategories = await getNavigation({
      subset: 'MOBILE_WEB,FLYOUT',
      queryParams: headerClientConfigQueryParameters,
    });
    setFlyoutCategories(navCategories);
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      isTabletPortrait() &&
      !flyoutCategories?.child
    ) {
      addMediaQueryListener(fetchFlyoutCategories);
    }
  }, []);

  useEffect(() => {
    if (mobileNavShown) {
      postMonetateImpression(monetate, 'include-params', monetateExperiences);
      postMonetateImpression(
        monetate,
        'insert-categories',
        monetateExperiences
      );
    }
  }, [mobileNavShown]);

  return mobileNavShown ? (
    <MobileMenu
      menus={menus}
      fetchChildCategories={fetch}
      isCleanMobileNavActive={isCleanMobileNavActive}
    />
  ) : (
    <>
      <DesktopMenu
        isTabbedNavActive={isTabbedNavActive}
        child={
          flyoutCategories.child ? flyoutCategories.child : categories.child
        }
      />
      {displaySEOLinks && <SEOLinks categories={categories.child} />}
    </>
  );
}

Navigation.propTypes = {
  mobileNavShown: PropTypes.bool.isRequired,
};

export default Navigation;
