import React from 'react';
import classNames from 'classnames';
import Link from '../ui/link/Link';
import styles from './link.scss';

const HeaderLink = props => (
  <Link
    {...props}
    itemClassName={styles.headerLinkItem}
    anchorClassName={classNames(styles.headerLink)}
  />
);

export default HeaderLink;
