import React from 'react';
import classnames from 'classnames';
import styles from './prenav-menu.scss';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';
import { notifyNavAnalytics } from '../../navigation/notify-analytics';

const PrenavMenu = ({ children, menuLinks = [], itemClassName }) => {
  const clickHandler = e => {
    dispatchMonetateQEvent(
      `prenav-menu-click.${e.target.innerHTML.replace(/ /g, '-').toLowerCase()}`
    );
    notifyNavAnalytics('pn', e.target.innerHTML);
  };

  if (!menuLinks?.length) return null;

  return (
    <div className={itemClassName} data-testid="prenav-menu">
      <div className={styles.menuOverlay} data-testid="menuOverlay">
        <div className={styles.menuOverlayHeader}>
          <h2>{children}</h2>
        </div>
        <ul className={styles.menuOverlayBody}>
          {menuLinks.map((menuLink, index) => (
            <li
              className={classnames(styles.item)}
              tabIndex={index}
              key={menuLink.name}
            >
              <a
                className={styles.link}
                href={menuLink.url}
                onClick={clickHandler}
                {...(menuLink.external && {
                  target: '_blank',
                  rel: 'noopener',
                })}
              >
                {menuLink.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrenavMenu;
