import classNames from 'classnames';
import React from 'react';
import styles from './basket-amount.scss';

const BasketAmount = ({ basketItems, isTabbedNavActive }) => (
  <>
    <span
      data-test="basket-amount"
      data-testid="basket-amount"
      className={
        basketItems > 0
          ? classNames(styles.icon, {
              [styles.icon__tn]: isTabbedNavActive,
            })
          : styles.visuallyHidden
      }
    >
      {basketItems}
    </span>
    <span className={styles.visuallyHidden}>items in Basket</span>
  </>
);
export default BasketAmount;
